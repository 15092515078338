

































































import { Vue, Component } from "vue-property-decorator";
import groupModule from "@/store/modules/groups";
import { Group } from "@/types/group";
import GroupItem from "@/components/group/GroupItem.vue";
import Paginate from "@/components/utility/Paginate.vue";

@Component({
  components: {
    GroupItem,
    Paginate,
  },
})
export default class Home extends Vue {
  get groups(): Group[] {
    return groupModule.groups;
  }

  get hasGroups(): boolean {
    return this.groups.length > 0;
  }

  get isLoaded(): boolean {
    return groupModule.status == "loaded";
  }
}
